(function($) {
    'use strict';

    const $window = $(window);
    const $document = $(document);
    const $body = $('body');

    const containerMinHeight = function() {
        $('#container').css({
            'min-height': $(window).height() - $('#container').offset().top - $('#footer').height() + 'px'
        });
    };

    containerMinHeight();
    $(document).ready(containerMinHeight);
    $(window).on('load', containerMinHeight);


    /**
     * Save scroll pos
     */
    const getUrl = function() { return [location.protocol, '//', location.host, location.pathname].join(''); }
    const scrollpos = JSON.parse(localStorage.getItem('scrollpos'));
    if (scrollpos && scrollpos.url == getUrl()) {
        $(window).scrollTop(scrollpos.pos);
    }
    $(window).on('unload', function() {
        localStorage.setItem('scrollpos', JSON.stringify({
            pos: $window.scrollTop(),
            url: getUrl()
        }));
    });


    /**
     * Smooth hash scroll
     */
    $('a[href*="#"]').not('[href="#"]').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            let target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 400);
                return false;
            }
        }
    });


    /**
     * Sticky submenu
     */
    $('#section-submenu').each(function() {
        let $section = $(this);
        let sectionPos = $section.offset().top

        let checkScrollPos = function() {
            if ($window.scrollTop() >= sectionPos) {
                $body.addClass('fixed-submenu-section');
            } else {
                $body.removeClass('fixed-submenu-section');
            }       

            window.requestAnimationFrame(checkScrollPos);
        }

        window.requestAnimationFrame(checkScrollPos);

        return false;
    });


    /**
     * Dynamic vh
     */
    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight();


    /**
     * Scrollable tables
     */
    $('table').each( function() {
        const $table = $(this);

        if ($table.width() > $table.parent().width()) {
            $table.wrap('<div class="table-container"><div style="overflow-y:auto; max-width:100%;"></div></div>');
        }
    });
})(jQuery);
